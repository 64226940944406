import type { ExploitatieHorecabedrijf as ExploitatieHorecabedrijfType } from '../../../server/services';
import { defaultDateFormat } from '../../../universal/helpers/date';
import { InfoDetail } from '../../components';
import { Location } from '../VergunningDetail/Location';

export default function ExploitatieHorecabedrijf({
  vergunning,
}: {
  vergunning: ExploitatieHorecabedrijfType;
}) {
  const isAfgehandeld = vergunning.processed;

  return (
    <>
      <InfoDetail label="Kenmerk" value={vergunning.identifier || '-'} />

      {!!vergunning.location && <Location location={vergunning.location} />}

      {isAfgehandeld && vergunning.decision === 'Verleend' && (
        <InfoDetail
          label="Begindatum"
          value={
            vergunning?.dateStart
              ? defaultDateFormat(vergunning.dateStart)
              : '-'
          }
        />
      )}

      {isAfgehandeld && (
        <InfoDetail label="Resultaat" value={vergunning.decision} />
      )}
    </>
  );
}
